<template>
  <div class="w-full flex justify-center">
    <div class="flex items-center">
      <img
        class="w-8" src="~@/assets/icons/arrow-circle-left.svg" alt="arrow-circle-left"
        @click="changeCurrent(current-1)"
      >
      <div
        v-for="val in firstValue"
        :key="val"
        :class="['item', {'item--active': val === current}]"
        @click="changeCurrent(val)"
      >
        {{ val }}</div>
      <div
        v-if="visibleMiddleSeparator"
        class="mx-1 text-xl">
        ...
      </div>
      <div
        class="flex items-center"
        v-else>
        <div
          class="mx-1 text-xl">
          ...
        </div>
        <div
          v-for="val in middleValue"
          :key="val"
          :class="['item', {'item--active': val === current}]"
          @click="changeCurrent(val)"
        >
          {{ val }}</div>
        <div
          class="mx-1 text-xl">
          ...
        </div>
      </div>
      <div
        v-for="val in lastValue"
        :key="val"
        :class="['item', {'item--active': val === current}]"
        @click="changeCurrent(val)"
      >
        {{ val }}</div>
      <img
        class="w-8" src="~@/assets/icons/arrow-circle-right.svg"
        alt="arrow-circle-right"
        @click="changeCurrent(current + 1)"
      >
    </div>
  </div>
</template>

<script>
const FIRST_LIMIT = 3
const LAST_LIMIT = 2
export default {
  name: 'Pagination',
  data () {
    return {
      current: 1
    }
  },
  props: {
    itemPerPage: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    }
  },
  computed: {
    totalPage () {
      return Math.ceil(this.size / this.itemPerPage)
    },
    inFirst () {
      return this.current <= FIRST_LIMIT - 1
    },
    inLast () {
      return this.current > (this.totalPage - LAST_LIMIT + 1)
    },
    firstValue () {
      return Array.from({ length: FIRST_LIMIT }, (_, i) => i + 1)
    },
    lastValue () {
      return [this.totalPage - 1, this.totalPage]
    },
    middleValue () {
      const result = []
      if (!this.firstValue.includes(this.current - 1)) result.push(this.current - 1)
      if (!this.firstValue.includes(this.current) && !this.lastValue.includes(this.current))result.push(this.current)
      if (!this.lastValue.includes(this.current + 1)) result.push(this.current + 1)
      return result
    },
    visibleMiddleSeparator () {
      return this.inFirst || this.inLast
    }
  },
  methods: {
    changeCurrent (val) {
      this.current = val
    }
  },
  watch: {
    current () {
      this.$emit('change', this.current)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  @apply flex justify-center items-center w-7 h-7 bg-blue-700 text-white rounded-full text-sm mx-0.5;
  &--active {
    @apply bg-blue-400;
  }
}
</style>
